import React, { useState, useEffect, useRef, useReducer } from "react";
import Head from "./head";
import { baseURL } from "../../api/config";
import { getTravelGuidePageBlogList } from "../../api/apiCall";
import Pagination from "./pagination";
import { actionTypes, reducer } from "./blogReducer";
import SkeletonLoader from "../../components/SkeltonLoader";
import TravelGuideCard from "./travelGuideCard";

const initialState = {
  isLoading: false,
  blogList: {},
  currentPage: 1,
  totalPages: 0,
  imagePath: "",
  error: null,
};

const TravelGuide = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // const [filter, setFilter] = useState("*");
  const mainSection = useRef(null);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePageChange = (page) => {
    scrollTop();
    if (state.blogList[page]) {
      return dispatch({ type: actionTypes.UPDATE_PAGE_NO, payload: page });
    }
    fetchData(`/${page}`);
  };

  const fetchData = async (payload) => {
    dispatch({ type: actionTypes.FETCH_REQUEST }); //loading true
    const result = await getTravelGuidePageBlogList(payload);
    dispatch({ type: actionTypes.FETCH_SUCCESS, payload: result });
    // setTimeout(()=>scrollToDiv(),500);
  };

  useEffect(() => {
    document.title = "Travel Guide | Know Rules";
    const payload = "/1";
    fetchData(payload);
    scrollTop();
  }, []);

  return (
    <>
      <Head />
      <div className="portfolio-gallery-five pt-30">
        <div className="container">
          <div ref={mainSection} className="row pt-30 lg-pt-10 ">
            {state.blogList[state.currentPage] &&
            state.blogList[state.currentPage].length > 0
              ? state.blogList[state.currentPage].map((item) => (
                  <div key={item.id} className={`col-lg-4 col-md-6`}>
                    <div className="portfolio-block-five hvr-shutter-out mb-50 lg-mb-30">
                      <TravelGuideCard
                        item={item}
                        imgSrc={baseURL + state.imagePath + item.fullimage}
                      />
                    </div>
                  </div>
                ))
              : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                  <div key={item.id} className={`col-lg-4 col-md-6`}>
                    <SkeletonLoader />
                  </div>
                ))}
          </div>
          <div id="pagination">
            <Pagination
              pages={state.totalPages}
              currentPage={state.currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TravelGuide;
