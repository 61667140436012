const ArrowUpSVG = ({ fillColor,className,width='24',height='24'})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -800 960 960"
            height={height} 
            width={width}
            className={className}
            fill={fillColor}
        >
          <path d="M440-160v-487L216-423l-56-57 320-320 320 320-56 57-224-224v487h-80Z"/>
        </svg>
    )
}

export default ArrowUpSVG;
