import React from "react";
import {Link} from "react-router-dom";

// import MainFooter from "./MainFooter";
import NewsLetter from "./NewsLetter";
import CopyrightFooter2 from "./CopyrightFooter2";

const links= [
  { label: "Home", url: "/" },
  { label: "About Us", url: "/about-us" },
  { label: "Travel Guides", url: "/travel-guides" },
  { label: "Contact Us", url: "/contact" },
  { label: "Terms & Conditions", url: "/terms-and-conditions" },
  { label: "Privacy Policy", url: "/privacy-policy" },
]

const DefaulFooter = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="footer-style-eleven theme-basic-footer position-relative">
      <div className="bg-wrapper position-relative">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-4 col-lg-2 footer-intro mb-40">
              <div className="logo">
                <Link to="/">
                  <img src="/assets/images/logo-know.png" alt="Know Rules" width={95} height={95} loading="lazy"/>
                </Link>
              </div>
            </div>

            <div className="col-7 col-lg-4 mb-30" >
              <h5 className="footer-title tx-dark fw-normal">Quick Links</h5>
              <ul className="footer-nav-link style-none">
                {links.map((link, index) => (
                  <li key={index}>
                    <Link to={link.url} onClick={scrollToTop}>{link.label}</Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-lg-4 mb-30 form-widget">
              <h5 className="footer-title tx-dark fw-normal">Newsletter</h5>
                <h6 className="newsletter pt-5 pb-10 md-pt-10">Join our newsletter</h6>
                <NewsLetter />
                <div className="newsletter fs-14 mt-10">
                  Know Rules and Travel Hassle Free!
                </div>
            </div>
          </div>
        </div>
      </div>
      <CopyrightFooter2 />
      {/* <img
        src="/images/shape/shape_173.svg"
        alt="shape"
        className="lazy-img shapes shape-one"
      /> */}
    </div>
  );
};

export default DefaulFooter;
