import React, { useState, useRef, useEffect } from "react";
import Typed from 'typed.js';
import { searchAirport, getDetailedGuidline, searchBlogs } from '../../api/apiCall';
import FlightUpSVG from "../../svg/flightUpSVG";
import { Link } from "react-router-dom";

const SearchInput = () => {
    const [typed, setTyped] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [inputOpen, setInputOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [searchResult, setSearchResult] = useState({})
    const [showResult, setShowResults] = useState(false)

    const el = useRef(null);
    const inputRef = useRef(null);
    const buttonRef = useRef(null);
    const searchRef = useRef(null);

    React.useEffect(() => {
        const typedInstance = new Typed(el.current, {
            strings: [
                'Know Airport Guidelines...',
                'Know Airport Screening',
                'Know Health and Safety Tips for Travelers',
                'Know Boarding Pass and Security Check',
            ],
            typeSpeed: 50,
            backSpeed: 30,
            loop: true,
            smartBackspace: true,
        });
        setTyped(typedInstance);
        return () => {
            if (typedInstance) {
                typedInstance.destroy();
            }
        };
    }, []);

    const handleClick = () => {
        setInputOpen(true);
        setTimeout(() => {
            inputRef.current && inputRef.current.focus(); // Ensure inputRef.current is not null before calling focus()
        }, 0);
        if (typed) {
            typed.stop(); // stop Typed instance
        }
    };

    const handleSearch = async () => {
        if (inputValue.trim() !== "") {
            try {
                setLoading(true)
                const airportResult = await searchAirport(`query=${inputValue}&fromParam=${inputValue}&toParam=&searchType=from`);
                const airportList = Object.values(airportResult.data).flatMap(country => Object.values(country).flat());
                // const guideLineResult = await getDetailedGuidline({ "slug": inputValue })
                const blogResult = await searchBlogs(`topic=${inputValue}`)
                setSearchResult({ airport: airportList, blog: blogResult })
                setShowResults(true)
            } catch (error) {
                console.log("Error fetching search data:", error);
            }
        }
    };
    const handleBlur = (event) => {
        // Check if the new focused element is the search button
        if (buttonRef.current && buttonRef.current.contains(event.relatedTarget)) {
            return; // Don't close the input if the focus is on the button
        }
        setInputOpen(false);
        if (typed) {
            typed.start();
        }
    };

    const handleOutside = () => {
        setShowResults(false)
        setInputOpen(false);
        if (typed) {
            typed.start();
        }
    }

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setShowResults(false);
        }
        if (typed) {
            typed.start();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const onChangeFun = (e) => {
        setInputValue(e.target.value)
        handleSearch()
    }

    return (
        <div className={"search-nav search-nav--visible"}>
            <span ref={el} style={{ display: inputOpen ? 'none' : 'inline-block', fontWeight: "600" }} onClick={handleClick} />
            <input
                type="text"
                className="search-nav__input"
                placeholder="Search"
                ref={inputRef}
                value={inputValue}
                onChange={onChangeFun}
                onBlur={handleBlur}
                style={{ display: inputOpen ? 'inline-block' : 'none' }}
            />
            <button
                type="button"
                className="search-nav__btn btn"
                onClick={handleSearch}
                ref={buttonRef}
                style={{ display: inputOpen ? 'inline-block' : 'none' }}
            >
                {/* <i className="fa-solid fa-magnifying-glass-location"></i> */}
            </button>
            {
                showResult &&
                <div id="nav-search-result" className="search-results" ref={searchRef} >
                    <ul style={{ maxHeight: '200px', overflowY: 'auto' }}>
                        <li disabled="disabled" className="disabled dropdownheader padding-left10 padding-top5 padding-bottom5" > Airports</li>
                        {searchResult?.airport?.length > 0 ?
                            searchResult.airport.map(item =>
                                <li onClick={handleOutside} key={item.id} className="airport-name"  >
                                    <Link to={`/airport/${item.slug}`} className="truncated-text" >
                                        <FlightUpSVG className={"flight-svg-list"} height="15" width="15" />{" "}
                                        {item.airportFullName}
                                    </Link>
                                </li>
                            )
                            :
                            <li className="airport-name">
                                No Airport found
                            </li>
                        }
                    </ul>

                    <ul style={{ maxHeight: '200px', overflowY: 'auto' }}>
                        <li disabled="disabled" className="disabled dropdownheader padding-left10 padding-top5 padding-bottom5"> Blogs</li>
                        {searchResult?.blog?.length > 0 ?
                            searchResult.blog.map(item =>
                                <li onClick={handleOutside} key={item.id} className="airport-name">
                                    <Link to={`/blog/${item?.tagname?.slug}`} className="truncated-text">
                                        {/* {item.topic.length > 150 ? item.topic.slice(0, 150) + '...' : item.topic} */}
                                        {item.topic}
                                    </Link>
                                </li>
                            )
                            :
                            <li className="airport-name">
                                No Blog found
                            </li>
                        }
                    </ul>
                </div>
            }
        </div >
    )
}
export default SearchInput;
