import React,{useState,useEffect} from "react";
import {getCarouselImagesListObj} from "../../api/apiCall";
import GuidelineImages from './guidelineImages';

const accordionItems = [
    {
      id: 1,
      icon: "/images/icon/icon_108.svg",
      title: "Arriving early",
      content:`Most airports recommend that passengers arrive at least two hours before their flight, 
            to allow time for check-in, security screenings, and any other necessary processes.`,
    },
    {
      id: 2,
      icon: "/images/icon/icon_109.svg",
      title: "Checking in",
      content:`Passengers may be required to check in online or at a kiosk before arriving at the airport. 
        Some airports may also require passengers to have a printed copy of their boarding pass.`,
    },
    {
      id: 3,
      icon: "/images/icon/icon_110.svg",
      title: "Security screenings",
      content:`All passengers and their luggage are required to go through security screenings before boarding their flight. 
        This usually includes going through a metal detector and having carry-on luggage and personal items scanned.`,
    },
    {
        id: 4,
        icon: "/images/icon/icon_110.svg",
        title: "Prohibited items",
        content:`There are certain items that are not allowed in carry-on or checked luggage, such as sharp objects, flammable materials, 
            and weapons. It is important to check with the airline and the airport for a complete list of prohibited items.`,
    },
    {
        id: 5,
        icon: "/images/icon/icon_110.svg",
        title: "Boarding the plane",
        content:`Passengers are usually required to present their boarding pass and a valid government-issued ID before boarding the plane. 
            They may also be asked to show their boarding pass and ID at other points during the journey, such as when transferring between flights.`,
    },
    {
        id: 6,
        icon: "/images/icon/icon_110.svg",
        title: "Health and safety measures",
        content:`Many airports have implemented additional health and safety measures in response to the COVID-19 pandemic, such as mask mandates and social distancing requirements. It is important to follow these guidelines to protect yourself and others.
        It is always a good idea to check with the airport and the airline before traveling to make sure you are aware of any specific guidelines that may apply to your trip..`,
    },
  ];
const GuidelineSection = () => {
    const [guidelineImages,setGuidelineImages] = useState({});
    useEffect(()=>{
        const fetchData = async () => {
            const result = await getCarouselImagesListObj({"image_type": 2})
            setGuidelineImages(result);
        };
        fetchData();
    },[]);
    
    return(
        <div className="feedback-section-eleven mt-100 sm-mt-10 pt-70 pb-70">
            <div className="container">
                <div className="title-style-one mb-40">
                    <div className="sc-title text-uppercase">Why Choose Us</div>
                        <h2 className="main-title fw-400 tx-dark m0" data-aos="fade-down" >
                            Airport Guidelines
                        </h2>
                    <div className="guide-body" data-aos="fade-down" >
                        <p>Airport guidelines can vary depending on the airport and the country in which it is located. In general, however, airports often have guidelines in place for the safety and security of passengers, staff, and the facility itself. Some common airport guidelines include:-</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 order-lg-last aos-init aos-animate">
                        <div className="ps-lg-5 ms-xxl-3">
                            <div className="accordion accordion-style-five md-mb-70" id="accordionOne">
                                {accordionItems.map((item) => (
                                    <div className="accordion-item" key={item.id}  data-aos="fade-left">
                                        <div className="accordion-header" id={`heading${item.id}`}>
                                            <button
                                                className={`accordion-button ${item.id === 1 ? "" : "collapsed"}`}
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapse${item.id}`}
                                                aria-expanded={item.id === 1 ? "true" : "false"}
                                                aria-controls={`collapse${item.id}`}
                                            >
                                                {/* <img src={item.icon} alt="" className="me-3" /> */}
                                                 {item.title}
                                            </button>
                                        </div>
                                        <div
                                            id={`collapse${item.id}`}
                                            className={`accordion-collapse collapse${
                                            item.id === 1 ? " show" : ""
                                            }`}
                                            aria-labelledby={`heading${item.id}`}
                                            data-bs-parent="#accordionOne"
                                        >
                                            <div className="accordion-body">
                                                <p>{item.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-5 col-lg-6 order-lg-first pt-20">
                        <GuidelineImages {...guidelineImages} />
                    </div>
                </div>
            </div>
        </div> 
    );
}
export default GuidelineSection;