import React from "react";

const Map = () => {
  return (
    <div className="map-area-two h-300 w-100">
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            title="address"
            className="gmap_iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28018.187648786487!2d77.070999648273!3d28.621565296612538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d04afa040cb01%3A0x7d779265b2445aa7!2sJanakpuri%2C%20New%20Delhi%2C%20Delhi%20110058!5e0!3m2!1sen!2sin!4v1671458063722!5m2!1sen!2sin"
          />
        </div>
      </div>
    </div>
  );
};

export default Map;
