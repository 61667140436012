import React, { useEffect } from "react";
import Speciality from "./speciality";
import HeroBanner from "./heroBanner";
import GuidelineSection from './guidelines';
import TravelGuidelineBlogSection from './travelGuidelineBlogSection';
import CovidSection from './covidSection';
import TravelBlogSection from './travelBlogSection';
import LazyLoad from 'react-lazyload';

const LandingPage = () => {
    useEffect(() => {
        document.title = "Home | Know Rules";
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }, []);

    return (
        <>
            <LazyLoad height={200} offset={100}>
                <HeroBanner />
            </LazyLoad>
            <LazyLoad height={200} offset={100}>
                <Speciality />
            </LazyLoad>
            <LazyLoad height={200} offset={100}>
                <GuidelineSection />
            </LazyLoad>
            <LazyLoad height={200} offset={100}>
                <TravelGuidelineBlogSection />
            </LazyLoad>
            <LazyLoad height={200} offset={100}>
                <CovidSection />
            </LazyLoad>
            <LazyLoad height={200} offset={100}>
                <TravelBlogSection />
            </LazyLoad>
        </>
    )
}
export default LandingPage;