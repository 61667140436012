import React from "react";
import FacebookSVG from "../../svg/facebookSVG";
import TwitterSVG from '../../svg/twitterSVG';
import InstaSVG from '../../svg/instaSVG';

const Footer = () => {
  return (
    <div className="bottom-footer lg-pb-10 position-relative">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6 order-lg-2">
            <ul className="d-flex justify-content-center justify-content-lg-end social-icon style-none">
              <li>
                <a href={'https://www.facebook.com/knowrulesinternational'} aria-label="Facebook" target="_blank" rel="noopener noreferrer">
                  <FacebookSVG className="social-icon"/>
                </a>
              </li>
              <li>
                <a href={'https://www.instagram.com/knowrulesinternational/'} aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                  <InstaSVG className="social-icon"/>
                </a>
              </li>
              <li>
                <a href={'https://twitter.com/KnowRules_com'} aria-label="Twitter" target="_blank" rel="noopener noreferrer">
                  <TwitterSVG className="social-icon"/>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-8 col-sm-6 order-lg-1">
            <p className="copyright m0">
              Copyright © {new Date().getFullYear()}{" "}
              <a
                style={{ color: "inherit" }}
                href="/"
                target="_blank"
                rel="noopener noreferrer"
              >
               Know Rules International Pvt. Ltd. | All Rights Reserved
              </a>
              {/* inc. */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
