import React,{useEffect,useState} from "react";
import GuideLinesCard from "../../components/GuideLinesCard";
import { getHomePageGuideLinestypeBlogList } from "../../api/apiCall";
import { baseURL } from "../../api/config";
import SkeletonLoader from "../../components/SkeltonLoader";

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const TravelGuidelineBlogSection = () => {
    const [blogList,setBlogList] = useState({})
    useEffect(()=>{
        const fetchData = async () => {
            const payload={"isShowOnHome": 1}
            const result = await getHomePageGuideLinestypeBlogList(payload)
            setBlogList(result); 
        };
        fetchData();
      },[]);

    return(
        <div className="feedback-section-ten pt-60 pb-60 trvl-g-b">
            <div className="container">
                <div className="title-style-one mb-20">
                    <h3 className="main-title fw-400 tx-dark m0" data-aos="fade-down" >
                        What Guidelines should you follow while Travelling abroad?
                    </h3>
                </div>
                <div className="row pt-20">
                    {/* {list.map(i=><GuideLinesCard {...i} key={i.id}/>)} */}
                    {/* <Slider  className="slider"{...settings}> */}
                        {blogList && blogList.allGuidelinesDetailObj?.length>0 
                            ?
                            blogList.allGuidelinesDetailObj.map(data=>(
                                    <div className="col-lg-4 col-md-6" key={data.id}>
                                        <GuideLinesCard baseURL={baseURL+blogList.imagePath} {...data}  key={data.id}/>
                                    </div>
                                    ))
                            :[0,1,2].map(i=>
                                <div className="col-lg-4 col-md-6" key={i}>
                                    <SkeletonLoader/>
                                </div>
                            )
                        }
                    {/* </Slider> */}
                </div>
            </div>
        </div>  
    );
}
export default TravelGuidelineBlogSection;