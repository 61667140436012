export default function FlightDownSVG({ fillColor,className,width='40',height='40' }) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            height={height} 
            viewBox="0 -960 960 960" 
            width={width}
            className={className}
            fill={fillColor}
        >
            <path d="M754-324 120-500v-220l60 20 28 84 192 54v-318l80 20 110 350 200 56q23 6 36.5 24.5T840-388q0 33-27 53t-59 11ZM120-120v-80h720v80H120Z"/>
       </svg>
    );
  }