import React,{useEffect,useState} from "react";
import BlogDetailLoader from "../../components/BlogDetailLoader";
import {getPageContent} from "../../api/apiCall";
import { useLocation } from "react-router-dom";
const TermAndConditions = () => {
    const [content, setContent] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
        document.title = `${location.pathname.split("/")[1]} | Know Rules`;
        setLoading(true);
        getPageContent({contentslug:location.pathname.split("/")[1]}).then((response) => {
            setContent(response.data.getPageContentObj);
            setLoading(false);
        });
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          })
    }, [location.pathname]);
    return(
        <div className="contact-section-four pt-150 pb-150">
            <div className="container">
                {
                    !isLoading && content.length>0 && content[0].id ?
                    <div>
                        <h2 className=" main-title text-center mb-50">{content[0].content_typesName}</h2>
                        <div dangerouslySetInnerHTML={{__html:content[0].description}}/>
                    </div>
                    : 
                    <div className="container">
                        <BlogDetailLoader/>
                    </div>
                }
            </div>
        </div>
    );
}
export default TermAndConditions;