import React,{useEffect,useState} from 'react';
import { getDetailedGuidline,getDRelatedGuidlines } from '../../api/apiCall';
import { useParams,Link } from 'react-router-dom';
import { baseURL } from "../../api/config";
import BlogDetailLoader from  '../../components/BlogDetailLoader';

const GuidlineDetail = () =>{
    const [blogDetail, setBlogDetail] = useState(null);
    const [relatedGuidelines, setRelatedGuidelines] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const { blog_slug } = useParams();
    const fetchData = async() => {
        setLoading(true)
        const result = await getDetailedGuidline({"slug":blog_slug})
        setBlogDetail(result.data)
        setLoading(false)
        const {guidelinestype_id,id} = result.data.getGuidelineDetailsObj
        const related = await getDRelatedGuidlines({'guidelines_details_id' : id, 'guidelinestype_id':guidelinestype_id})
        setRelatedGuidelines(related.data.getRelatedGuidelinesObj)
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    };
    useEffect(()=>{
        document.title = "Guideline | Know Rules";
        fetchData()
    },[blog_slug]);

    return(
        <div className="detail-blog-sec pt-100 pb-50">
            <div className="container">
            {
                !isLoading && blogDetail?.getGuidelineDetailsObj?.id ?
                <div className='row'>
                    <div className='col-lg-8'>
                        <img src={baseURL+blogDetail.imagePath+blogDetail.getGuidelineDetailsObj.images} className='main-img' loading="lazy" alt={blogDetail.getGuidelineDetailsObj.title} />
                        <div className='head mb-10'>
                            <h2>{blogDetail.getGuidelineDetailsObj.guidelinesType}</h2>
                            <div className="sc-title-two fst-italic position-relative">
                                {blogDetail.getGuidelineDetailsObj.title}
                            </div>
                        </div>
                        <div className="guideline-details" dangerouslySetInnerHTML={{__html:blogDetail.getGuidelineDetailsObj.description}}/>
                    </div>
                    <div className='col-lg-4'>
                        <div className='related-guideline'>
                            <h2>Related GuideLines</h2>
                            {
                                relatedGuidelines.map((item,index)=>(
                                  <div className='col-lg-12 related-guide-card' key={item.id}>
                                    <h4>{item.title}</h4>
                                    <span dangerouslySetInnerHTML={{__html:item.short_description}}/>
                                    <Link to={`/guidelines/${item.slug}`}> 
                                        <div className='quick-view'>
                                            Quick View
                                        </div>
                                    </Link>
                                    
                                  </div> 
                                ))
                            }
                        </div>
                    </div>
                </div>
                    :
                    <div className="container">
                        <BlogDetailLoader/>
                    </div>
                }
            </div>
        </div>
    );
};
export default GuidlineDetail;


