import React from "react";
const AddressBlock = () => {
  const address = [
    {
      icon: "/assets/icon/icon_178.svg",
      title: "Our Address",
      content: "New Delhi,India,110058",
      delay: "100",
    },
    {
      icon: "/assets/icon/icon_181.svg",
      title: "Contact Info",
      content: "Open a chat or give us call at",
      link: "tel:99999 99999",
      delay: "200",
    },
    {
    icon: "/assets/icon/icon_179.svg",
      title: "Email Support",
      content: "Send a mail at",
      link: "mailto:info@knowrules.com",
      delay: "300",
    },
  ];

  return (
    <>
      {address.map((block, index) => (
        <div
          className="col-md-4"
          key={index}
          data-aos="fade-up"
          data-aos-delay={block.delay}
        >
          <div className="address-block-two text-center mb-40">
            <div className="icon border rounded-circle d-flex align-items-center justify-content-center m-auto">
              <img width={30} height={30} src={block.icon} alt="icon" loading="lazy" />
            </div>
            <h5 className="title">{block.title}</h5>
            <p>
              {block.content} <br />
              {block.link && (
                <a
                  href={block.link}
                  className={
                    block.link.includes("tel:") ? "call" : "webaddress"
                  }
                >
                  {block.link.replace("tel:", "")}
                </a>
              )}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default AddressBlock;
