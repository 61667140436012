export const languageList= [
    {
        code: "en",
        name: "English",
        ename: "English",
    },
    {
        code: "af",
        name: "Afrikaans",
        ename: "Afrikaans",
    },
    {
        code: "sq",
        name: "Albanian",
        ename: "Albanian",
    },
    {
        code: "am",
        name: "Amharic",
        ename: "Amharic",
    },
    {
        code: "ar",
        name: "Arabic",
        ename: "Arabic",
    },
    {
        code: "hy",
        name: "Armenian",
        ename: "Armenian",
    },
    {
        code: "az",
        name: "Azerbaijani",
        ename: "Azerbaijani",
    },
    {
        code: "eu",
        name: "Basque",
        ename: "Basque",
    },
    {
        code: "be",
        name: "Belarusian",
        ename: "Belarusian",
    },
    {
        code: "bn",
        name: "Bengali",
        ename: "Bengali",
    },
    {
        code: "bs",
        name: "Bosnian",
        ename: "Bosnian",
    },
    {
        code: "bg",
        name: "Bulgarian",
        ename: "Bulgarian",
    },
    {
        code: "my",
        name: "Burmese",
        ename: "Burmese",
    },
    {
        code: "ca",
        name: "Catalan",
        ename: "Catalan",
    },
    {
        code: "ceb",
        name: "Cebuano",
        ename: "Cebuano",
    },
    {
        code: "ny",
        name: "Chichewa",
        ename: "Chichewa",
    },
    {
        code: "zh-CN",
        name: "Chinese (Simplified)",
        ename: "Chinese (Simplified)",
    },
    {
        code: "zh-TW",
        name: "Chinese (Traditional)",
        ename: "Chinese (Traditional)",
    },
    {
        code: "co",
        name: "Corsican",
        ename: "Corsican",
    },
    {
        code: "hr",
        name: "Croatian",
        ename: "Croatian",
    },
    {
        code: "cs",
        name: "Czech",
        ename: "Czech",
    },
    {
        code: "da",
        name: "Danish",
        ename: "Danish",
    },
    {
        code: "nl",
        name: "Dutch",
        ename: "Dutch",
    },
    {
        code: "eo",
        name: "Esperanto",
        ename: "Esperanto",
    },
    {
        code: "et",
        name: "Estonian",
        ename: "Estonian",
    },
    {
        code: "tl",
        name: "Filipino",
        ename: "Filipino",
    },
    {
        code: "fi",
        name: "Finnish",
        ename: "Finnish",
    },
    {
        code: "fr",
        name: "French",
        ename: "French",
    },
    {
        code: "fy",
        name: "Frisian",
        ename: "Frisian",
    },
    {
        code: "gl",
        name: "Galician",
        ename: "Galician",
    },
    {
        code: "ka",
        name: "Georgian",
        ename: "Georgian",
    },
    {
        code: "de",
        name: "German",
        ename: "German",
    },
    {
        code: "el",
        name: "Greek",
        ename: "Greek",
    },
    {
        code: "gu",
        name: "Gujarati",
        ename: "Gujarati",
    },
    {
        code: "ht",
        name: "Haitian Creole",
        ename: "Haitian Creole",
    },
    {
        code: "ha",
        name: "Hausa",
        ename: "Hausa",
    },
    {
        code: "haw",
        name: "Hawaiian",
        ename: "Hawaiian",
    },
    {
        code: "iw",
        name: "Hebrew",
        ename: "Hebrew",
    },
    {
        code: "hi",
        name: "Hindi",
        ename: "Hindi",
    },
    {
        code: "hmn",
        name: "Hmong",
        ename: "Hmong",
    },
    {
        code: "hu",
        name: "Hungarian",
        ename: "Hungarian",
    },
    {
        code: "is",
        name: "Icelandic",
        ename: "Icelandic",
    },
    {
        code: "ig",
        name: "Igbo",
        ename: "Igbo",
    },
    {
        code: "id",
        name: "Indonesian",
        ename: "Indonesian",
    },
    {
        code: "ga",
        name: "Irish Gaelic",
        ename: "Irish Gaelic",
    },
    {
        code: "it",
        name: "Italian",
        ename: "Italian",
    },
    {
        code: "ja",
        name: "Japanese",
        ename: "Japanese",
    },
    {
        code: "jw",
        name: "Javanese",
        ename: "Javanese",
    },
    {
        code: "kn",
        name: "Kannada",
        ename: "Kannada",
    },
    {
        code: "kk",
        name: "Kazakh",
        ename: "Kazakh",
    },
    {
        code: "km",
        name: "Khmer",
        ename: "Khmer",
    },
    {
        code: "rw",
        name: "Kinyarwanda",
        ename: "Kinyarwanda",
    },
    {
        code: "ko",
        name: "Korean",
        ename: "Korean",
    },
    {
        code: "ku",
        name: "Kurdish",
        ename: "Kurdish",
    },
    {
        code: "ky",
        name: "Kyrgyz",
        ename: "Kyrgyz",
    },
    {
        code: "lo",
        name: "Lao",
        ename: "Lao",
    },
    {
        code: "la",
        name: "Latin",
        ename: "Latin",
    },
    {
        code: "lv",
        name: "Latvian",
        ename: "Latvian",
    },
    {
        code: "lt",
        name: "Lithuanian",
        ename: "Lithuanian",
    },
    {
        code: "lb",
        name: "Luxembourgish",
        ename: "Luxembourgish",
    },
    {
        code: "mk",
        name: "Macedonian",
        ename: "Macedonian",
    },
    {
        code: "mg",
        name: "Malagasy",
        ename: "Malagasy",
    },
    {
        code: "ms",
        name: "Malay",
        ename: "Malay",
    },
    {
        code: "ml",
        name: "Malayalam",
        ename: "Malayalam",
    },
    {
        code: "mt",
        name: "Maltese",
        ename: "Maltese",
    },
    {
        code: "mi",
        name: "Maori",
        ename: "Maori",
    },
    {
        code: "mr",
        name: "Marathi",
        ename: "Marathi",
    },
    {
        code: "mn",
        name: "Mongolian",
        ename: "Mongolian",
    },
    {
        code: "ne",
        name: "Nepali",
        ename: "Nepali",
    },
    {
        code: "no",
        name: "Norwegian",
        ename: "Norwegian",
    },
    {
        code: "or",
        name: "Odia (Oriya)",
        ename: "Odia (Oriya)",
    },
    {
        code: "ps",
        name: "Pashto",
        ename: "Pashto",
    },
    {
        code: "fa",
        name: "Persian",
        ename: "Persian",
    },
    {
        code: "pl",
        name: "Polish",
        ename: "Polish",
    },
    {
        code: "pt",
        name: "Portuguese",
        ename: "Portuguese",
    },
    {
        code: "pa",
        name: "Punjabi",
        ename: "Punjabi",
    },
    {
        code: "ro",
        name: "Romanian",
        ename: "Romanian",
    },
    {
        code: "ru",
        name: "Russian",
        ename: "Russian",
    },
    {
        code: "sm",
        name: "Samoan",
        ename: "Samoan",
    },
    {
        code: "gd",
        name: "Scots Gaelic",
        ename: "Scots Gaelic",
    },
    {
        code: "sr",
        name: "Serbian",
        ename: "Serbian",
    },
    {
        code: "st",
        name: "Sesotho",
        ename: "Sesotho",
    },
    {
        code: "sn",
        name: "Shona",
        ename: "Shona",
    },
    {
        code: "sd",
        name: "Sindhi",
        ename: "Sindhi",
    },
    {
        code: "si",
        name: "Sinhala",
        ename: "Sinhala",
    },
    {
        code: "sk",
        name: "Slovak",
        ename: "Slovak",
    },
    {
        code: "sl",
        name: "Slovenian",
        ename: "Slovenian",
    },
    {
        code: "so",
        name: "Somali",
        ename: "Somali",
    },
    {
        code: "es",
        name: "Spanish",
        ename: "Spanish",
    },
    {
        code: "su",
        name: "Sundanese",
        ename: "Sundanese",
    },
    {
        code: "sw",
        name: "Swahili",
        ename: "Swahili",
    },
    {
        code: "sv",
        name: "Swedish",
        ename: "Swedish",
    },
    {
        code: "tg",
        name: "Tajik",
        ename: "Tajik",
    },
    {
        code: "ta",
        name: "Tamil",
        ename: "Tamil",
    },
    {
        code: "tt",
        name: "Tatar",
        ename: "Tatar",
    },
    {
        code: "te",
        name: "Telugu",
        ename: "Telugu",
    },
    {
        code: "th",
        name: "Thai",
        ename: "Thai",
    },
    {
        code: "tr",
        name: "Turkish",
        ename: "Turkish",
    },
    {
        code: "tk",
        name: "Turkmen",
        ename: "Turkmen",
    },
    {
        code: "uk",
        name: "Ukrainian",
        ename: "Ukrainian",
    },
    {
        code: "ur",
        name: "Urdu",
        ename: "Urdu",
    },
    {
        code: "ug",
        name: "Uyghur",
        ename: "Uyghur",
    },
    {
        code: "uz",
        name: "Uzbek",
        ename: "Uzbek",
    },
    {
        code: "vi",
        name: "Vietnamese",
        ename: "Vietnamese",
    },
    {
        code: "cy",
        name: "Welsh",
        ename: "Welsh",
    },
    {
        code: "xh",
        name: "Xhosa",
        ename: "Xhosa",
    },
    {
        code: "yi",
        name: "Yiddish",
        ename: "Yiddish",
    },
    {
        code: "yo",
        name: "Yoruba",
        ename: "Yoruba",
    },
    {
        code: "zu",
        name: "Zulu",
        ename: "Zulu",
    }
]