import React, { useRef, useEffect } from "react";
import CompareSVG from "../svg/compareSVG";
import FlightDownSVG from "../svg/flightDownSVG";
import FlightUpSVG from "../svg/flightUpSVG";

const LeftSearchResults = (props) => {
  return (
    <div
      id="left-search-results"
      className="search-results left"
      ref={props.leftDropdownRef}
    >
      <div>
        {Object.keys(props.fromResult.data).map((country, index) => (
          <ul key={index}>
            <li
              disabled="disabled"
              className="disabled dropdownheader padding-left10 padding-top5 padding-bottom5"
            >
              {country}
            </li>
            <ul>
              {Object.keys(props.fromResult.data[country]).map(
                (city, index) => (
                  <React.Fragment key={index}>
                    <li
                      key={index}
                      disabled="disabled"
                      className="disabled dropdownheader padding-left10 padding-top5 padding-bottom5"
                    >
                      {country} - {city}
                    </li>
                    {props.fromResult.data[country][city].map(
                      (airport, index) => (
                        <li
                          onClick={() => props.selectLeft(airport)}
                          key={index}
                          className="airport-name"
                        >
                          <FlightUpSVG
                            className={"flight-svg-list"}
                            height="15"
                            width="15"
                          />{" "}
                          {airport.name_of_the_airport} - {airport.iata_code}{" "}
                          <span className="padding-left5">
                            ({airport.cityName},{airport.countryName})
                          </span>
                        </li>
                      )
                    )}
                  </React.Fragment>
                )
              )}
            </ul>
          </ul>
        ))}
      </div>
    </div>
  );
};

const SearchFromToComponent = (props) => {
  const { setFromVal, fetchFromData, fromVal } = props;
  const { setToVal, fetchToData, toVal } = props;
  const { selectLeft, selectRight } = props;
  const { fromResult, toResult, swapFunction } = props;
  const { onFocusLeft, onFocusRight } = props;
  const leftDropdownRef = useRef(null);
  const rightDropdownRef = useRef(null);

  useEffect(() => {
    // Close dropdown when user clicks outside of it
    const handleClickOutsideLeft = (event) => {
      if (
        leftDropdownRef.current &&
        !leftDropdownRef.current.contains(event.target)
      ) {
        // Clicked outside dropdown
        setFromVal(""); // Clear input value to close dropdown
      }
    };
    const handleClickOutsideRight = (event) => {
      if (
        rightDropdownRef.current &&
        !rightDropdownRef.current.contains(event.target)
      ) {
        // Clicked outside dropdown
        setToVal(""); // Clear input value to close dropdown
      }
    };

    // Attach event listener when component mounts
    document.addEventListener("mousedown", handleClickOutsideLeft);
    document.addEventListener("mousedown", handleClickOutsideRight);

    // Detach event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideLeft);
      document.removeEventListener("mousedown", handleClickOutsideRight);
    };
  }, []);

  useEffect(() => {
    var headerHeight = document.querySelector("header").offsetHeight;
    document.getElementById("from").addEventListener("click", function () {
      if (window.innerWidth <= 1024) {
        var inputTop = this.getBoundingClientRect().top;
        var scrollPosition = window.scrollY + inputTop - headerHeight - 30;
        window.scrollTo({ top: scrollPosition, behavior: "smooth" });
      }
    });
    document.getElementById("to").addEventListener("click", function () {
      if (window.innerWidth <= 1024) {
        var inputTop = this.getBoundingClientRect().top;
        var scrollPosition = window.scrollY + inputTop - headerHeight - 30;
        window.scrollTo({ top: scrollPosition, behavior: "smooth" });
      }
    });
  }, []);

  return (
    <div className="approval-info d-inline-flex align-items-center mt-60 lg-mt-30">
      <div className="search-area d-md-inline-flex m-auto">
        <div className="standard-input left" data-aos="fade-up-right">
          <input
            type="text"
            name="from"
            placeholder=" "
            value={fromVal}
            onChange={(e) => {
              setFromVal(e.target.value);
              fetchFromData(e.target.value);
            }}
            maxLength={25}
            id="from"
            onFocus={onFocusLeft}
          />
          <label htmlFor="from">
            <FlightUpSVG className={"flight-svg"} height="25" />
            Travelling From
          </label>
          {window.innerWidth > 500 && (
            <div>
              {fromVal &&
                fromResult.status &&
                Object.keys(fromResult.data).length > 0 && (
                  <LeftSearchResults
                    leftDropdownRef={leftDropdownRef}
                    fromResult={fromResult}
                    selectLeft={selectLeft}
                  />
                )}
            </div>
          )}
        </div>
        {window.innerWidth < 500 && (
          <div>
            {fromVal &&
              fromResult.status &&
              Object.keys(fromResult.data).length > 0 && (
                <LeftSearchResults
                  leftDropdownRef={leftDropdownRef}
                  fromResult={fromResult}
                  selectLeft={selectLeft}
                />
              )}
          </div>
        )}
        <div className="exchange-route-container">
          <div
            className="exchange-route"
            data-aos="fade-down"
            onClick={swapFunction}
          >
            <CompareSVG className={"exchange-route-svg"} />
          </div>
        </div>
        {/* {console.log(fromVal && fromResult.status && Object.keys(fromResult.data)?.length>0 ,'--',!!(fromVal && fromResult.status && Object.keys(fromResult.data)?.length>0))} */}
        <div className={`standard-input right `} data-aos="fade-up-left">
          <input
            type="text"
            name="to"
            placeholder=" "
            value={toVal}
            maxLength={25}
            id="to"
            onChange={(e) => {
              setToVal(e.target.value);
              fetchToData(e.target.value);
            }}
            onFocus={onFocusRight}
          />
          <label htmlFor="to">
            Travelling To <FlightDownSVG className={"flight-svg"} height="25" />
          </label>
          {toVal &&
            toResult.status &&
            Object.keys(toResult.data).length > 0 && (
              <div
                id="right-search-results"
                className="search-results right"
                ref={rightDropdownRef}
              >
                <div>
                  {Object.keys(toResult.data).map((country, index) => (
                    <ul key={index}>
                      <li
                        disabled="disabled"
                        className="disabled dropdownheader padding-left10 padding-top5 padding-bottom5"
                      >
                        {country}
                      </li>
                      <ul>
                        {Object.keys(toResult.data[country]).map(
                          (city, index) => (
                            <React.Fragment key={index}>
                              <li
                                // key={index}
                                disabled="disabled"
                                className="disabled dropdownheader padding-left10 padding-top5 padding-bottom5"
                              >
                                {country} - {city}
                              </li>
                              {toResult.data[country][city].map(
                                (airport, index) => (
                                  <li
                                    onClick={() => selectRight(airport)}
                                    key={index}
                                    className="airport-name"
                                  >
                                    <FlightDownSVG
                                      className={"flight-svg-list"}
                                      height="15"
                                      width="15"
                                    />{" "}
                                    {airport.name_of_the_airport} -{" "}
                                    {airport.iata_code}{" "}
                                    <span className="padding-left5">
                                      ({airport.cityName}, {airport.countryName}
                                      )
                                    </span>
                                  </li>
                                )
                              )}
                            </React.Fragment>
                          )
                        )}
                      </ul>
                    </ul>
                  ))}
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
export default SearchFromToComponent;
