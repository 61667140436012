import Axios from "axios";

// Create an Axios instance with a base URL
// export const baseURL = "http://43.205.236.78";
// const baseURL ="https://admin.knowrules.com";

export const baseURL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const axios = Axios.create({ baseURL: baseURL });

// Request interceptor to attach the Authorization header
axios.interceptors.request.use(
  (config) => {
    const token = false;
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    config.headers.Accept = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
