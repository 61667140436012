import React from "react";
import Lottie from "lottie-react";
import Calendar from "../../lottie-animation/Calendar.json";
import Group from "../../lottie-animation/Group2.json";
import Search1 from "../../lottie-animation/Search1.json";
import Update2 from "../../lottie-animation/Update2.json";

const Speciality =()=>{
    return(
        <div className="speciality-block container pt-50 mb-50">
            <div className="row align-items-end justify-content-center">
                <div className="col-6 col-sm-4 col-md-5" data-aos="zoom-in-down">
                    <div className="block-wrapper block-zero">
                        <Lottie
                            animationData={Update2}
                            className="icon"
                        />
                        <h3 > <span>Up to date Info</span></h3>
                        <p>Get the latest info for Airport, Customs, Security, Covid-19 Rules and more.</p>
                    </div>
                </div>
                <div className="col-6 col-sm-4 col-md-5" data-aos="zoom-in-down">
                    <div className="block-wrapper block-one">
                        <Lottie
                            animationData={Search1}
                            className="icon"
                        />
                        <h3 > <span>Easy Search Options</span></h3>
                        <p>Search for your destination using the Country/City/Airport name.</p>
                    </div>
                </div>
            </div>
            {/* End .row */}

            <div className="row align-items-end justify-content-center">
                    <div className="col-6 col-sm-4 col-md-5 "  data-aos="fade-up-right">
                        <div className="block-wrapper block-two position-relative mt-20 sm-mt-20">
                            <Lottie
                                animationData={Group}
                                className="icon"
                            />
                            <h3> <span>Build a Community</span></h3>
                            <p>Provide feedback to Help Others!</p>
                        </div>
                        {/* /.block-wrapper */}
                    </div>
                    {/* End .col */}

                    <div className="col-6 col-sm-4 col-md-5" data-aos="fade-up-left">
                        <div className="block-wrapper block-three mt-20 sm-mt-20">
                            <Lottie
                                animationData={Calendar}
                                className="icon"
                            />
                            <h3 > <span>Reliable Information</span>  </h3>
                            <p>Find reliable information quickly, every time.</p>
                        </div>
                        {/* /.block-wrapper */}
                    </div>
            </div>
    </div>
    )
}
export default Speciality;