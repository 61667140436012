import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import WorldLottie from "../../lottie-animation/world.json";
import { Link } from "react-router-dom";
import { searchAirport } from "../../api/apiCall";
import SearchFromToComponent from "../../components/SearchFromToComponent";
import { searchAirportGuideLine } from "../../api/apiCall";
import GuideLinesCard from "../../components/GuideLinesCard";
import { baseURL } from "../../api/config";
import FullPageLoader from "../../components/FullPageLoader";
const createAirportArray = (input) => {
  return input;
};

const AirportGuideLines = (props) => {
  const [airportData, setAirportData] = useState({});
  const [fromVal, setFromVal] = useState(""); // show in input field
  const [toVal, setToVal] = useState(""); // show in input field

  const [fromValObject, setFromValObject] = useState(null); // store left selected airport object
  const [toValObject, setToValObject] = useState(null); // store right selected airport object

  const [fromResult, setFromReslt] = useState({}); // render result list
  const [toResult, setToResult] = useState({}); // render result list

  // const navigation = useNavigate();

  const fetchFromData = async (value) => {
    if (value && value.length > 2) {
      const result = await searchAirport(
        `query=${value}&fromParam=${value}&toParam=&searchType=from`
      );
      const newArr = createAirportArray(result.data);
      setFromReslt({ data: newArr, status: "success" });
    }
  };
  const fetchToData = async (value) => {
    if (value && value.length > 2) {
      const result = await searchAirport(
        `query=${value}&fromParam=&toParam=${value}&searchType=to`
      );
      const newArr = createAirportArray(result.data);
      setToResult({ data: newArr, status: "success" });
    }
  };

  const selectLeft = (airportData) => {
    setFromVal(airportData.airportFullName);
    setFromValObject(airportData);
    setFromReslt({});
    const storage = localStorage.getItem("searchFromToAirport");
    if (storage) {
      // if already exising
      const data = JSON.parse(storage);
      localStorage.setItem(
        "searchFromToAirport",
        JSON.stringify({
          ...data,
          from_name: airportData.airportFullName,
          from_id: airportData.id,
        })
      );
    } else {
      localStorage.setItem(
        "searchFromToAirport",
        JSON.stringify({
          from_name: airportData.airportFullName,
          from_id: airportData.id,
        })
      );
    }
    return window.location.reload();
  };

  const selectRight = (airportData) => {
    setToVal(airportData.airportFullName);
    setToValObject(airportData);
    setToResult({});
    const storage = localStorage.getItem("searchFromToAirport");
    if (storage) {
      // if already exising
      const data = JSON.parse(storage);
      localStorage.setItem(
        "searchFromToAirport",
        JSON.stringify({
          ...data,
          to_name: airportData.airportFullName,
          to_id: airportData.id,
        })
      );
    } else {
      localStorage.setItem(
        "searchFromToAirport",
        JSON.stringify({
          to_name: airportData.airportFullName,
          to_id: airportData.id,
        })
      );
    }
    return window.location.reload();
  };

  const swapFunction = () => {
    if (fromValObject && toValObject) {
      const data = JSON.parse(localStorage.getItem("searchFromToAirport"));
      localStorage.setItem(
        "searchFromToAirport",
        JSON.stringify({
          to_name: data.from_name,
          to_id: data.from_id,
          from_name: data.to_name,
          from_id: data.to_id,
        })
      );
      setFromVal(data.to_name);
      setToVal(data.from_name);

      const temp = fromValObject;
      setFromValObject(toValObject);
      setToValObject(temp);
      return window.location.reload();
      // fetch({from_id:fromValObject.id,to_id:toValObject.id})
    }
  };

  const fetch = async (param) => {
    // setLoading(true)
    const result = await searchAirportGuideLine(param);
    setAirportData(result.data);
    // setLoading(false)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onFocusLeft = () => {
    setToResult({});
  };
  const onFocusRight = () => {
    setFromReslt({});
  };

  useEffect(() => {
    document.title = "Airport Guideline | Know Rules";
    const data = JSON.parse(localStorage.getItem("searchFromToAirport"));
    setFromVal(data.from_name);
    setFromValObject({ name: data.from_name, id: data.from_id });
    setToVal(data.to_name);
    setToValObject({ name: data.to_name, id: data.to_id });
    fetch({ from_id: data.from_id, to_id: data.to_id });
  }, []);

  return (
    <div className="fancy-feature-fiftyOne airport-search-result position-relative mt-150 sm-mt-100">
      <div className="container">
        <Lottie
          animationData={WorldLottie}
          className="lottie-animation-airport-result left"
        />
        <Lottie
          animationData={WorldLottie}
          className="lottie-animation-airport-result right"
        />
        <div className="row">
          <div
            className="col-lg-9 col-md-10 m-auto text-center"
            data-aos="fade-up"
          >
            <h1 className="hero-heading fw-500 tx-dark">
              <span>Search Airport For Desired Locations</span>
            </h1>
            <p className="text-lg tx-dark mt-35 mb-30 lg-mt-20 lg-mb-10">
              Find Airport Rules & Regulations, City & Country
            </p>
          </div>

          <SearchFromToComponent
            setFromVal={setFromVal}
            fetchFromData={fetchFromData}
            fromVal={fromVal}
            setToVal={setToVal}
            fetchToData={fetchToData}
            toVal={toVal}
            selectLeft={selectLeft}
            selectRight={selectRight}
            fromResult={fromResult}
            toResult={toResult}
            swapFunction={swapFunction}
            onFocusLeft={onFocusLeft}
            onFocusRight={onFocusRight}
          />
        </div>
      </div>
      <div className="container-fluid">
        {Object.keys(airportData).length > 0 ? (
          <div className="pt-200 sm-pt-100">
            <div className="row">
              <div className="col-6">
                {airportData.getFormAirportDetailsObj.map((airport) => (
                  <div
                    className="row"
                    style={{ textAlign: "center" }}
                    key={airport.id}
                  >
                    <h3 className="airport-name">
                      <Link to={`/airport/${airport.slug}`}>
                        {airport.name_of_the_airport}{" "}
                        <i className="fa-solid fa-link"></i>{" "}
                      </Link>
                    </h3>
                    <span>
                      {airport.cityName},{airport.stateName},
                      {airport.countryName}
                    </span>
                  </div>
                ))}
              </div>

              <div className="col-6">
                {airportData.getToAirportDetailsObj.map((airport) => (
                  <div
                    className="row"
                    style={{ textAlign: "center" }}
                    key={airport.id}
                  >
                    <h3 className="airport-name">
                      <Link to={`/airport/${airport.slug}`}>
                        {airport.name_of_the_airport}{" "}
                        <i className="fa-solid fa-link"></i>
                      </Link>
                    </h3>
                    <span>
                      {airport.cityName},{airport.stateName},
                      {airport.countryName}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div>
                  {airportData.getFormGuidelineListObj.map((section, index) => (
                    <div
                      className="row sm-pt-10 sm-pb-10  pt-30 pb-30 justify-content-center"
                      key={index}
                    >
                      <h5 className="blog-head">{section.name}</h5>
                      {section.allGuidelinesDetailObj.map((blog, index) => (
                        <div
                          className="col-lg-6 col-md-6 col-sm-12 col-12 mb-10"
                          key={index}
                        >
                          <GuideLinesCard
                            baseURL={baseURL + airportData.imagePath}
                            {...blog}
                            key={index}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-6">
                <div>
                  {airportData.getToGuidelineListObj.map((section, index) => (
                    <div
                      className="row sm-pt-10 sm-pb-10  pt-30 pb-30 justify-content-center"
                      key={index}
                    >
                      <h5 className="blog-head">{section.name}</h5>
                      {section.allGuidelinesDetailObj.map((blog, index) => (
                        <div
                          className="col-lg-6 col-md-6 col-sm-12 col-12 mb-10"
                          key={index}
                        >
                          <GuideLinesCard
                            baseURL={baseURL + airportData.imagePath}
                            {...blog}
                            key={index}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-6">
              <FullPageLoader className={"full-page-loader"} />
            </div>
            <div className="col-lg-6">
              <FullPageLoader className={"full-page-loader"} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AirportGuideLines;
/* <div className="row">
<div className="col-lg-6" >
<FullPageLoader width={500} height={400} />
</div>
<div className="col-lg-6" >
<FullPageLoader width={500} height={300}/>
</div>
</div> */
