export default function CompareSVG({ fillColor,className }) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            height="35" 
            viewBox="0 -960 960 960" 
            width="35"
            className={className}
            fill={fillColor}
        >
        <path d="m294-135-66-66 104-103H55v-95h277L228-503l66-66 218 217-218 217Zm372-257L449-609l217-217 66 66-103 104h277v95H629l103 103-66 66Z"/>
       </svg>
    );
  }