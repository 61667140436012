import React from "react";
import { Link } from 'react-router-dom';

const GuideLinesCard = (props)=>{
    return(
        <Link to={`/guidelines/${props.slug}`}>
            <div className="gen-guide-card" >
                <div className="card">
                    <div className="inner-card">
                        <div className="img-wrapper">
                            <img src={props.baseURL+props.images} alt={props.slug} loading="lazy"/>
                        </div>
                        <div className="content">
                            <h1>{props.title}</h1>
                            <p dangerouslySetInnerHTML={{__html:props.short_description}}/>
                        </div>
                        <div className="btn-wrapper">
                            <Link to={`/guidelines/${props.slug}`} className="view-btn">Read</Link>
                        </div>
                    </div>
                </div>
            </div>
     </Link>
    )
}

export default GuideLinesCard;