import React from "react";
import { baseURL } from "../api/config";
import { Link } from 'react-router-dom';

const HomeBlogCard = (props) =>{
    return(
        <Link to={`/blog/${props.slug}`} >
            <div className="card">
                <div className="card__header">
                    <img src={baseURL+props.imageUrl} alt={props.blog_typesname} className="card__image" width={500} loading="lazy"/>
                </div>
                <div className="card__body">
                    <span className="tag tag-blue">{props.blog_typesname}</span>
                    <h4>{props.topic}</h4>
                </div>
                <div className="card__footer">
                    {/* <div className="user">
                        <img src={'/assets/images/avatar.png'} alt="user__image" className="user__image"/>
                        <div className="user__info">
                        <h5>{props.first_name} {props.last_name}</h5>
                        <small>{props.post_date.split("T")[0]}</small>
                        </div>
                    </div> */}
                    <div className="read-more">
                        <Link to={`/blog/${props.slug}`} className="bttn">
                            Read
                        </Link>
                    </div>
                </div>
            </div>
        </Link>
    );
};
export default HomeBlogCard;