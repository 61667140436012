// GoogleTranslate.js
import React, { useState } from 'react';
import { languageList } from './api/languageList';
import GlobIconSVG from './svg/globIconSVG';

const GoogleTranslate = () => {
  const [selectedLang, setSelectedLang] = useState("English");

  const handleLanguageChange = (lang) => {
    console.log(lang);
    const translateDropdown = document.querySelector('.goog-te-combo');
    if (translateDropdown) {
      translateDropdown.value = lang.code;
      translateDropdown.dispatchEvent(new Event('change'));
      setTimeout(() => {
        setSelectedLang(lang.name);
      }, 0);
    }
  };

  return (
    <div className="dropdown">
      <button
        className="dropdown-toggle notranslate lang-btn"
        type="button"
        id="langDropdown"
        data-bs-toggle="dropdown"
        data-bs-auto-close="true"
        aria-expanded="false"
        translate="no"
      >
        <GlobIconSVG fillColor={"#fff"} className={'lang-icon'} />{' '}{selectedLang}
      </button>
      <ul className="lang-list dropdown-menu notranslate" aria-labelledby="langDropdown" translate="no">
        {languageList.map((lang) => (
          <li key={lang.code}>
            <span
              className="dropdown-item lang-item notranslate"
              onClick={() => handleLanguageChange(lang)}
              translate="no"
            >
              {lang.name}
            </span>
          </li>
        ))}
      </ul>
      <div id="google_translate_element" style={{ display: 'none' }}></div>
    </div>
  );
};

export default GoogleTranslate;
