import React from 'react';

function Pagination({ pages, currentPage, onPageChange }) {
  const createPagination = () => {
    let str = [];
    let pageCutLow = currentPage - 1;
    let pageCutHigh = currentPage + 1;

    str.push(
      <li key="previous" className="page-item previous">
        <button onClick={() => onPageChange(currentPage === 1? 1: currentPage - 1)}>{`<<`}</button>
      </li>
    );

    // Show all the pagination elements if there are less than 6 pages total
    if (pages < 6) {
      for (let p = 1; p <= pages; p++) {
        str.push(
          <li key={p} className={`page-item ${currentPage === p ? 'active' : ''}`}>
            <button onClick={() => onPageChange(p)}>{p}</button>
          </li>
        );
      }
    } else {
      // Show "..." at the beginning if currentPage is more than 2
      if (currentPage > 2) {
        // str.push(
        //   <li key="first" className="page-item">
        //     <button onClick={() => onPageChange(1)}>1</button>
        //   </li>
        // );
        // if (currentPage > 3) {
        //   str.push(
        //     <li key="outOfRangeBefore" className="out-of-range">
        //       <button onClick={() => onPageChange(currentPage - 2)}>...</button>
        //     </li>
        //   );
        // }
      }

      // Determine how many pages to show after the current page index
      if (currentPage === 1) {
        pageCutHigh += 2;
      } else if (currentPage === 2) {
        pageCutHigh += 1;
      }

      // Determine how many pages to show before the current page index
      if (currentPage === pages) {
        pageCutLow -= 2;
      } else if (currentPage === pages - 1) {
        pageCutLow -= 1;
      }

      // Output the indexes for pages that fall inside the range of pageCutLow and pageCutHigh
      for (let p = pageCutLow; p <= pageCutHigh; p++) {
        if (p === 0) {
          p += 1;
        }
        if (p > pages) {
          continue;
        }
        str.push(
          <li key={p} className={`page-item ${currentPage === p ? 'active' : ''}`}>
            <button onClick={() => onPageChange(p)}>{p}</button>
          </li>
        );
      }

      // Show the very last page preceded by a "..." at the end of the pagination section
      if (currentPage < pages - 1) {
        // if (currentPage < pages - 2) {
        //   str.push(
        //     <li key="outOfRangeAfter" className="out-of-range">
        //       <button onClick={() => onPageChange(currentPage + 2)}>...</button>
        //     </li>
        //   );
        // }
        // str.push(
        //   <li key="last" className="page-item">
        //     <button onClick={() => onPageChange(pages)}>{pages}</button>
        //   </li>
        // );
      }
    }

    // Show the ">>" button
    str.push(
      <li key="next" className="page-item next">
        <button onClick={() => onPageChange(currentPage ===pages ? pages : currentPage + 1)}>{`>>`}</button>
      </li>
    );

    return str;
  };

  return <ul>{createPagination()}</ul>;
}

export default Pagination;
