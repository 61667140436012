export default function FlightSVG({ fillColor,className,width='40',height='40' }) {
    return (
        <svg 
            data-v-1555d88b="" 
            aria-hidden="true" 
            focusable="false" 
            data-prefix="fas" 
            data-icon="plane" 
            role="img" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 576 512" 
            class="svg-inline--fa fa-plane fa-w-18"
            width={width}
            height={height}
            className={className}
            fill={fillColor}
            >
            <path 
                data-v-1555d88b="" 
                fill="currentColor" 
                d="M480 192H365.71L260.61 8.06A16.014 16.014 0 0 0 246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z" 
                class="">
            </path>
        </svg>

       
    );
  }


