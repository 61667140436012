import React, { useEffect, useState } from 'react';
import { getDetailedBlog } from '../../api/apiCall';
import { useParams } from 'react-router-dom';
import { baseURL } from "../../api/config";
import HomeBlogCard from '../../components/HomeBlogCard';
import BlogDetailLoader from '../../components/BlogDetailLoader';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BlogDetail = () => {
  const [blogDetail, setBlogDetail] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const { blog_slug } = useParams();
  const fetchData = async () => {
    setLoading(true)
    const result = await getDetailedBlog({ "slug": blog_slug })
    setBlogDetail(result.data)
    setLoading(false)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    document.title = "Article | Know Rules";
    fetchData()
  }, [blog_slug]);

  var settings = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    autoplaySpeed: 2500,
    cssEase: "linear",
    dots: true,
    // fade: true,
    vertical: true,
    verticalSwiping: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
          infinite: true,
          centerMode: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
          infinite: true,
          centerMode: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
          infinite: true,
          centerMode: true,
        }
      }
    ]
  };
  return (
    <div className="detail-blog-sec pt-120 pb-50">
      {
        !isLoading && blogDetail?.getBlogDetailObj?.length > 0 ?
          <div className="container">
            <div className='row'>
              <div className="col-lg-8 sm-12">
                <div className='blog-image'>
                  <img src={baseURL + blogDetail.imagePath + blogDetail.getBlogDetailObj[0].fullimage} className='main-img' alt='blog' loading="lazy" />
                </div>
                <div className='head mb-10 pt-5'>
                  <h3>{blogDetail.getBlogDetailObj[0].topic}</h3>
                </div>
                <div className='blog-content' dangerouslySetInnerHTML={{ __html: blogDetail.getBlogDetailObj[0].description }} />
              </div>
              <div className='col-lg-4 sm-12'>
                <h3 className='more-blogs pb-10'>More Travel Guides</h3>
                <div className='row justify-content-center align-items-center'>
                  <Slider {...settings}>
                    {
                      blogDetail.lastestBlogDetailObj.slice(0.10).map((item) => {
                        return (
                          <HomeBlogCard {...item} key={item.id} imageUrl={blogDetail.imagePath + item.fullimage} />
                        )
                      })
                    }
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="container">
            <BlogDetailLoader />
          </div>

      }
    </div>
  );
};
export default BlogDetail;
