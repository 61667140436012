import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from '../pages/LandingPage';
import TravelGuide from '../pages/TravelGuide';
import AboutUs from '../pages/AboutUs';
import BlogDetail from "../pages/BlogDetail";
import GuidlineDetail from "../pages/guidelineDetail";
import AirportGuideLines from '../pages/AirportGuidelines';
import AirportDetails from '../pages/AirportDetails';
import TermAndConditions from '../pages/TermAndConditions';
import ContactUs from '../pages/ContactUs';
const ProjectRoutes = () => (
    <Routes>
        <Route path="/" element={<LandingPage />}/>
        <Route exact path="/search" element={<AirportGuideLines/>} />
        <Route exact path="/travel-guides" element={<TravelGuide />}/>
        <Route exact path="/about-us" element={<AboutUs />}/>
        <Route exact path="/blog/:blog_slug" element={<BlogDetail/>} />
        <Route exact path="/guidelines/:blog_slug" element={<GuidlineDetail/>} />
        <Route exact path="/airport/:blog_slug" element={<AirportDetails/>} />
        {/* <Route path="*" render={() => <Redirect to="/" />} /> */}

        <Route exact path="/contact" element={<ContactUs/>} />
        <Route exact path="/terms-and-conditions" element={<TermAndConditions/>} />
        <Route exact path="/privacy-policy" element={<TermAndConditions/>} />

    </Routes>
);
export default ProjectRoutes;