import React, { useState, useEffect } from "react";
import { getHomePageBlogSlider } from "../../api/apiCall";
import HomeBlogCard from '../../components/HomeBlogCard';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TravelBlogSection = () => {
    const [blogList, setBlogList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const result = await getHomePageBlogSlider()
            setBlogList(result);
            setLoading(false)
        };
        fetchData();
    }, []);
    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        // centerMode: true,
        cssEase: "linear",
        // fade: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
            },
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                }
              },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    return (
        <div className="blog-carousal-section pb-50 pt-50 sm-pt-10">
            <div className="head">
                <h1 className="ribbon">Quick and Easy Travel Guides</h1>
            </div>
            <div className="home-page-blog pt-50">
                <Slider {...settings}>
                    { !isLoading && blogList.length>0 ?
                        blogList.map((item)=>
                            <HomeBlogCard {...item} key={item.id}/>)
                            :<></>
                    }
                </Slider>
            </div>
        </div>
    );
};
export default TravelBlogSection;