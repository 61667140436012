
import React from "react";
import {subscribe} from "../../api/apiCall";
import { toast } from 'react-toastify';

const NewsLetter = () => {
  const subscribeEmail = async (email) => {
    const result = await subscribe({email})
    if(result.error){
      toast.error(result.error)}
      else{
        toast.success(result.message)
      }
   
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    subscribeEmail(event.target[0].value)
  };

  return (
    <form onSubmit={handleSubmit} className="position-relative">
      <input type="email" placeholder="Enter your email" required />
      <button className="tran3s fw-500 position-absolute">Subscribe</button>
    </form>
  );
};

export default NewsLetter;
