import React, { useEffect } from "react";
import "./App.scss";
import "aos/dist/aos.css";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AOS from "aos";
import ProjectRoutes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TawkToWidget from "./components/TawkToWidget";
import GoogleTagManager from "./googleTagManager";
function App() {
  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,
    });
  }, []);
  return (
    <BrowserRouter>
      <GoogleTagManager />
      <Header />
      <ProjectRoutes />
      <Footer />
      <ToastContainer />
      <TawkToWidget />
    </BrowserRouter>
  );
}

export default App;
