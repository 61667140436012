import React,{ useState,useEffect } from "react";
import Lottie from "lottie-react";
import WalkingMan from "../../lottie-animation/WalkingMan.json";
import { getPageContent } from "../../api/apiCall";

const AboutUs = () => {
  const [content, setContent] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
      document.title = "About Us | Know Rules";
      setLoading(true);
      getPageContent({contentslug:"about-us"}).then((response) => {
          setContent(response.data.getPageContentObj);
          setLoading(false);
      });
      window.scrollTo({
          top: 0,
          behavior: "smooth",
          })
    }, []);
  const features = [
    { text: "Amazing communication." },
    { text: "Best trending designing experience." },
    { text: "Email & Live chat." },
  ];

  const starRating = Array(5)
    .fill()
    .map((_, index) => (
      <li key={index}>
        <i className="fa-solid fa-star" />
      </li>
    ));

  return (
    <>
      <div className="fancy-feature-fiftyOne position-relative mt-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-7" data-aos="fade-right">
              <div className="title-style-five mb-65 md-mb-40">
                <div className="sc-title-two fst-italic position-relative">
                  More About us.
                </div>
                <h2 className="main-title fw-500 tx-dark">
                  Our Mission
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-8 col-lg-9 ms-auto">
              <div className="ps-xxl-5" data-aos="fade-left">
                <h6 className="mb-30">Our History &amp; Goal.</h6>
                <p className="text-lg tx-dark">
                We are a small team of Travel Enthusiasts aiming to make travel easy and fun for everyone.
                We want you to have all your travel related information in one place so you don't have to worry about forgetting anything important when planning your next vacation!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* /.container */}
      
        <img
          src="/assets/shape/shape_171.svg"
          alt="shape"
          className="lazy-img shapes shape-one"
        />
          <div className="lazy-img shapes shape-two">
            <Lottie
                animationData={WalkingMan}
                style={{height: 300,zIndex: "-1"}}
            />
        </div>
       
        {/* <img
          src="/assets/shape/shape_172.svg"
          alt="shape"
          className="lazy-img shapes shape-two"
        /> */}
      </div>
     
      <div className="fancy-feature-thirtySeven mt-150 lg-mt-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div
                className="block-style-four pe-xxl-5 me-xxl-4 md-mb-70"
                data-aos="fade-right"
              >
                <div className="title-style-six">
                  <div className="sc-title-two text-uppercase">WHY US</div>
                  <h2 className="main-title fw-500 tx-dark">
                    Who we are?
                  </h2>
                </div>
                <p className="fs-20 pt-20 pb-30 lg-pt-10 lg-pb-20">
                    We are a small team of Travel Enthusiasts aiming to make travel easy and fun for everyone.
                    We want you to have all your travel related information in one place so you don't have to worry about forgetting anything important when planning your next vacation!
                </p>
                <ul className="style-none list-item">
                  {features.map((feature, index) => (
                    <li key={index}>{feature.text}</li>
                  ))}
                </ul>
                <div className="d-inline-flex align-items-center mt-35 lg-mt-20">
                  <ul className="d-flex style-none rating">{starRating}</ul>
                  <div className="fs-20 ms-2">
                    <strong className="fw-500 tx-dark">4.8</strong>
                    <span className="tx-dark fs-16 opacity-50">
                      (30k Reviews)
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-xxl-5 col-lg-6 ms-auto">
              <Block />
            </div> */}
          </div>
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-feature-thirtySeven */}

     
     
      {/* <div
        className="fancy-short-banner-sixteen mt-130 lg-mt-80 mb-30"
        data-aos="fade-up"
      >
        <div className="container">
          <div className="bg-wrapper pt-65 pb-65 lg-pt-40 lg-pb-40">
            <div className="row">
              <div className="col-xl-10 col-md-11 m-auto">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="text-wrapper text-center text-lg-start md-pb-30">
                      <div className="sc-title fs-18 pb-10">
                        Have Any Question?
                      </div>
                      <h2 className="main-title fw-500 text-white m0">
                        Don’t hesitate to send us message.
                      </h2>
                    </div>
                  </div>

                  <div className="col-lg-5 ms-auto text-center text-lg-end">
                    <Link
                      href="/contact"
                      className="btn-twentyOne fw-500 tran3s"
                    >
                      Get Started Today!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AboutUs;
