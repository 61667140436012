import { Link } from "react-router-dom"
const TravelGuideCard = ({item,imgSrc})=>{
    return(
        <Link to={`/blog/${item.slug}`} className="pj-title">
            <div className="blog-card">
                <div className="blog-card-date">{item.createdDateFormat}</div>
                <img className="blog-img" src={imgSrc} alt={item.topic} loading="lazy"/>
                <div className="text-overlay">
                    <h2>{item.topic}</h2>
                </div>
            </div>
        </Link>
    )
}

export default TravelGuideCard;