import React from "react";
import { Link, useLocation } from "react-router-dom";
import GoogleTranslate from '../../googleTranslate';

const MainMenu = () => {
  const {pathname} = useLocation();

  const isActive = (link) => {
    return pathname.replace(/\/\d+$/, "") === link.replace(/\/\d+$/, "");
  };

  return (
    <nav className="navbar navbar-expand-lg">
       {/* Mobile language dropdown */}
       <div className="d-block d-lg-none my-3">
          <GoogleTranslate />
        </div>
      <button
        className="navbar-toggler d-block d-lg-none"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span />
      </button>
      {/* End mobile collapse menu */}

      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          <li className="d-block d-lg-none">
            <div className="logo">
              <Link to="/" className="d-block">
                <img
                  src="/assets/images/logo-know.png"
                  alt="logo"
                  width={95}
                  height={70}
                />
              </Link>
            </div>
          </li>
          {/* End li */}
        
          <li className="nav-item dropdown mega-dropdown-md">
            <Link to="/"
              className={
                isActive("/") ? "nav-link dropdown-toggle active-menu" : "nav-link dropdown-toggle"
              }
              role="button"
            >
              Home
            </Link>
          </li>
          <li className="nav-item  dropdown">
            <Link to="/travel-guides"
              // className="nav-link dropdown-toggle"
              className={
                isActive("/travel-guides")  ? "nav-link dropdown-toggle active-menu"  : "nav-link dropdown-toggle"
              }
              role="button"
            >
              Travel Guides
            </Link>
          </li>
        </ul>
      </div>
       {/* Desktop language dropdown */}
       <div className="lang-dropdown position-relative ms-3 d-none d-lg-block">
        <GoogleTranslate />
      </div>
    </nav>
  );
};

export default MainMenu;

