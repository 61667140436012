export const API_ENDPOINTS = {
    'GET_ALL_GUIDELINELIST_API': '/api/v1/get/guidelines-type',
    'GET_GUIDELINE_DETAILS_API': '/api/v1/get/guidelines-details',
    'GET_RELATED_GUIDELINES_API': '/api/v1/get/related-guidelines',
    'GET_AIRPORT_LIST_API': '/api/v1/get/airport-list',
    'GET_AIRPORT_DETAILS_API': '/api/v1/get/airport-details',
    'GET_ALL_BLOGLIST_API': '/api/v1/get/blog-listing',
    'GET_BLOG_DETAILS_API': '/api/v1/get/blog-details',
    'SEARCH_BLOGS': '/api/v1/post/blogs',
    'GET_BLOG_TYPE_LIST_API': '/api/v1/get/blog-type/listing',
    'GET_PAGE_CONTENT_API': '/api/v1/get/page-content',
    'SEARCH_AIRPORT_LIST_API': '/api/v1/search-list-details',
    'SEARCH_AIRPORT_NAME_API': '/api/v1/search-airport-list',
    'SEARCH_AIRPORT_GUIDELINES_API': '/api/v1/search-airport-guideline-list',
    'GET_CURRENCY_COUNTRY_CODES_API': '/api/v1/get/currency-country-codes',
    'GET_CURRENCY_CONCERTER_API': '/api/v1/currency-converter',
    'GET_LATEST_BLOG_API': '/api/v1/get/latest-blog-listing',
    'GET_HOME_SLIDER_BLOG_API': '/api/v1/get/home-slider-blog-listing',
    'GET_NEAREST_AIRPORT_API': '/api/v1/get/nearest-airport',
    'GET_WEBSITE_SETTINGS_API': '/api/v1/get/website-settings',
    'CONTACT_FORM_SUBMIT_API': '/api/v1/post/contactus-detail-query',
    'SUBSCRIBE_EMAIL_SUBMIT_API': '/api/v1/post/mailchimp',
    'GALLERY_LIKES_AND_VOTES_API': '/api/v1/post/like-and-votes',
    'CLIENTELE_LIST_API': '/api/v1/get/clientele',
    'GALLERY_IMAGES_LIST_API': '/api/v1/get/gallery-images',
    'TRAVEL_AGENTS_LIST_API': '/api/v1/get/travel-agents',
    'GET_TRAVEL_AGENTS_DETAILS_API': '/api/v1/get/travel-agent-details',
    'GET_AIRPORT_DETAIL_API': '/api/v1/get/airport-detail',
    'GET_CLIENT_LOCATION_DETAILS_API': '/api/v1/get/client-locations',
    'GET_IPINFO_TOKEN': '/api/v1/get/ipinfo-token',
};


export default API_ENDPOINTS;
