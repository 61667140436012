export default function FlightUpSVG({ fillColor,className,width='40',height='40' }) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            height={height} 
            viewBox="0 -960 960 960" 
            width={width}
            className={className}
            fill={fillColor}
        >
        <path d="M120-120v-80h720v80H120Zm74-200L80-514l62-12 70 62 192-52-162-274 78-24 274 246 200-54q32-9 58 12t26 56q0 22-13.5 39T830-492L194-320Z"/>
       </svg>
    );
  }