export const actionTypes = {
    FETCH_REQUEST: 'FETCH_REQUEST',
    FETCH_SUCCESS: 'FETCH_SUCCESS',
    FETCH_FAILURE: 'FETCH_FAILURE',
    UPDATE_PAGE_NO: 'UPDATE_PAGE_NO',
  };
  
  export const reducer = (state, action) => {
    switch (action.type) {
      case actionTypes.FETCH_REQUEST:
        return { ...state, isLoading: true };
      case actionTypes.FETCH_SUCCESS:
        const {imagePath,totalPages,currentPage,getBlogsObj} = action.payload
        return { ...state, isLoading: false ,imagePath,totalPages,currentPage, blogList: {...state.blogList,[currentPage]:getBlogsObj}  };
      case actionTypes.FETCH_FAILURE:
        return { ...state, isLoading: false, error: action.payload };
      case actionTypes.UPDATE_PAGE_NO:
        return { ...state, currentPage: action.payload };
      default:
        return state;
    }
  };