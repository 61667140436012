import React,{useEffect,useState} from "react";
import { getPageContent } from '../../api/apiCall';
import AddressBlock from './addreddBlock';
import Map from './map';

const ContactUs = () => {
    const [content, setContent] = useState([]);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        document.title = "Contact Us | Know Rules";
        setLoading(true);
        getPageContent({contentslug: 'contact-us'}).then((response) => {
            setContent(response.data.getPageContentObj);
            setLoading(false);
        });
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          })
    }, []);

    return (
        <div className="contact-section-four pt-180 md-pt-150">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-7 col-lg-6 col-md-7 m-auto">
                        <div
                            className="title-style-fourteen text-center mb-100 lg-mb-50"
                            data-aos="fade-up"
                        >
                            <div className="sc-title">CONTACT US</div>
                            <h2 className="main-title font-recoleta fw-normal tx-dark">
                            Our{" "}
                            <span className="position-relative">
                                team <img src="/assets/shape/shape_180.svg" alt="icon" loading="lazy" />
                            </span>
                            are here to help you.
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <AddressBlock />
                </div>
                {
                    !isLoading && content.length > 0 && (
                        <div className="row contact-us justify-content-center pt-50">
                            <div className="title-style-fourteen text-center col-lg-8 col-sm-12" data-aos="fade-up">
                                <div className="sc-title">{content[0].title}</div>
                                <p  dangerouslySetInnerHTML={{__html:content[0].description}}/>
                            </div>
                        </div>
                    )
                }
            </div>

            <div className="contact-meta mt-110 lg-mt-40">
                <div className="row gx-0">
                    {/* <div className="col-lg-6 order-lg-last d-flex">
                        <div className="form-wrapper pt-60 lg-pt-40 pb-85 lg-pb-50 w-100">
                            <div className="form-style-five">
                                <h3 className="form-title font-recoleta fw-normal pb-30 lg-pb-20">
                                    Send Message.
                                </h3>
                                <Form />
                            </div>
                        </div>
                    </div> */}
                    <div className="col-lg-12 order-lg-first d-flex">
                        <Map />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ContactUs;