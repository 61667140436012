import React from "react";
const staticData = {
    "title": "Latest update on COVID-19",
    "content": "As we resume operations, considering the COVID-19 situation, different state governments have laid out their respective protocols for passengers arriving at their airports. Before you travel, make sure to read and understand the complete guidelines of the city/state you are travelling to and comply with the same so as to ease your travel plans."
}
const CovidSection = () => {
    return (
        <div className="covid-sec pt-50 pb-50 mb-50">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-6">
                        <img className="covid-img" src="/assets/images/covid.webp" alt="covid" height={300} width={450} loading="lazy" />
                    </div>
                    <div className="col-lg-5" data-aos="fade-left">
                        <div className="content-sec pt-15">
                            <h5> {staticData.title} </h5>
                            <div className="component">
                                <blockquote className="callout quote EN">
                                    {staticData.content}
                                </blockquote>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CovidSection;