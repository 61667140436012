import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { baseURL } from "../../api/config";

export default function GuidelineImages(props) {
    var settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      centerMode: true,
    //   centerPadding: "60px",
      fade: true,
    };
    return (
        <div>
          <Slider {...settings}>
            {
              props.galleryImageObj && 
                props.galleryImageObj.length>0 && 
                  props.galleryImageObj.map((item,inx)=>(
                  <img src={ baseURL + props.imagePath + item.images } key={item.id} alt={item.images} width={500} height={300} loading="lazy"/>
                  ))
            }
          </Slider>  
        </div>
    );
  }