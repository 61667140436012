import React, { useState } from "react";
import { searchAirport } from "../../api/apiCall";
import { useNavigate } from "react-router-dom";
import SearchFromToComponent from "../../components/SearchFromToComponent";

const HeroBanner = () => {
  const [fromVal, setFromVal] = useState(""); // show in input field
  const [toVal, setToVal] = useState(""); // show in input field

  const [fromValObject, setFromValObject] = useState(null); // store left selected airport object
  const [toValObject, setToValObject] = useState(null); // store right selected airport object

  const [fromResult, setFromReslt] = useState({}); // render result list
  const [toResult, setToResult] = useState({}); // render result list

  const navigation = useNavigate();
  const fetchFromData = async (value) => {
    if (value && value.length > 2) {
      const result = await searchAirport(
        `query=${value}&fromParam=${value}&toParam=&searchType=from`
      );
      const newArr = result.data
      setFromReslt({ data: newArr, status: "success" });
    }
  };
  const fetchToData = async (value) => {
    if (value && value.length > 2) {
      const result = await searchAirport(
        `query=${value}&fromParam=&toParam=${value}&searchType=to`
      );
      const newArr = result.data;
      setToResult({ data: newArr, status: "success" });
    }
  };

  const selectLeft = (airportData) => {
    setFromVal(airportData.airportFullName);
    setFromValObject(airportData);
    setFromReslt({});
    const storage = localStorage.getItem("searchFromToAirport");
    if (storage) {
      // if already exising
      const data = JSON.parse(storage);
      localStorage.setItem(
        "searchFromToAirport",
        JSON.stringify({
          ...data,
          from_name: airportData.airportFullName,
          from_id: airportData.id,
        })
      );
    } else {
      localStorage.setItem(
        "searchFromToAirport",
        JSON.stringify({
          from_name: airportData.airportFullName,
          from_id: airportData.id,
        })
      );
    }
    if (toValObject) {
      setTimeout(() => navigation("/search"), 500);
    }
  };

  const selectRight = (airportData) => {
    setToVal(airportData.airportFullName);
    setToValObject(airportData);
    setToResult({});
    const storage = localStorage.getItem("searchFromToAirport");
    if (storage) {
      // if already exising
      const data = JSON.parse(storage);
      localStorage.setItem(
        "searchFromToAirport",
        JSON.stringify({
          ...data,
          to_name: airportData.airportFullName,
          to_id: airportData.id,
        })
      );
    } else {
      localStorage.setItem(
        "searchFromToAirport",
        JSON.stringify({
          to_name: airportData.airportFullName,
          to_id: airportData.id,
        })
      );
    }
    if (fromValObject) {
      setTimeout(() => navigation("/search"), 500);
    }
  };

  // const swapFunction = () => {
  //   localStorage.setItem(
  //     "searchFromToAirport",
  //     JSON.stringify({
  //       from_name: fromValObject.airportFullName,
  //       from_id: fromValObject.id,
  //       to_name: toValObject.airportFullName,
  //       to_id: toValObject.id,
  //     })
  //   );
  //   setTimeout(() => navigation("/search"), 500);
  // };

  const onFocusLeft = () => {
    setToResult({});
  };
  const onFocusRight = () => {
    setFromReslt({});
  };

  return (
    <div className="hero-banner-ten position-relative zn2">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-9 col-md-10 m-auto text-center"
            data-aos="fade-up"
          >
            <h1 className="hero-heading fw-500 tx-dark">
              Know Rules &amp; <span>Travel Hassle Free </span>
            </h1>
            <p className="text-lg tx-dark mt-35 mb-50 lg-mt-20 lg-mb-10">
              Know everything about rules for Airlines, Security, Customs, Local
              Regulations and more..
            </p>
          </div>
          <SearchFromToComponent
            setFromVal={setFromVal}
            fetchFromData={fetchFromData}
            fromVal={fromVal}
            setToVal={setToVal}
            fetchToData={fetchToData}
            toVal={toVal}
            selectLeft={selectLeft}
            selectRight={selectRight}
            fromResult={fromResult}
            toResult={toResult}
            onFocusLeft={onFocusLeft}
            onFocusRight={onFocusRight}
            // swapFunction={swapFunction}
          />
        </div>
      </div>

      <img
        // width={487}
        height={649}
        src="/assets/images/ils_11-min.webp"
        alt="ilustration"
        className="lazy-img illustration-one"
        data-aos="fade-left"
      />
      <img
        // width={537}
        height={658}
        src="/assets/images/ils_12-min.webp"
        alt="ilustration"
        className="lazy-img illustration-two"
        data-aos="fade-right"
      />
    </div>
  );
};

export default HeroBanner;
